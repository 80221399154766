<template>
    <div>
        <v-card tile>
            <v-card-text>
                <v-container>
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-select
                                    :items="['Group Of Users','Single Users']"
                                    @onchange="onSendNotificationToChange"
                                    label="Send Notification To"
                                    outlined
                                    dense
                                    required
                                    :error-messages="messageSendNotificationErrors"
                                    @input="$v.editedItem.sendNotificationTo.$touch()"
                                    @blur="$v.editedItem.sendNotificationTo.$touch()"
                                    v-model="editedItem.sendNotificationTo"
                                    prepend-icon=""
                                    append-icon="mdi-access-point"
                            ></v-select>
                            <v-select
                                    v-if="editedItem.sendNotificationTo === 'Group Of Users'"
                                    :items="['Active Users','Subscribed Users']"
                                    label="Type of Users"
                                    outlined
                                    dense
                                    required
                                    multiple
                                    chips
                                    @input="editedItem.sendNotificationTo === 'Group Of Users' ? $v.editedItem.users.$touch() : undefined"
                                    @blur="editedItem.sendNotificationTo === 'Group Of Users' ? $v.editedItem.users.$touch() : undefined"
                                    v-model="editedItem.users"
                                    :error-messages="editedItem.sendNotificationTo === 'Group Of Users' ? usersErrors : []"
                                    prepend-icon=""
                                    append-icon="mdi-account"
                            ></v-select>
                            <v-text-field
                                    v-if="editedItem.sendNotificationTo === 'Single Users'"
                                    outlined
                                    dense
                                    v-model="editedItem.playerIds"
                                    :error-messages="editedItem.sendNotificationTo === 'Single Users' ? messagePlayerIdErrors : []"
                                    required
                                    @input="editedItem.sendNotificationTo === 'Single Users' ? $v.editedItem.playerIds.$touch() : undefined"
                                    @blur="editedItem.sendNotificationTo === 'Single Users' ? $v.editedItem.playerIds.$touch() : undefined"
                                    label="Player Ids"
                                    append-icon="mdi-account-search"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-file-input
                                    label="Logo"
                                    outlined
                                    dense
                                    v-model="editedItem.img_url"
                                    prepend-icon=""
                                    append-icon="mdi-image"
                            ></v-file-input>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                    outlined
                                    dense
                                    v-model="editedItem.message"
                                    :error-messages="messageErrors"
                                    required
                                    @input="$v.editedItem.message.$touch()"
                                    @blur="$v.editedItem.message.$touch()"
                                    label="Message"
                                    append-icon="mdi-rename-box"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-alert v-if="error" dense type="error">
                                {{error}}
                            </v-alert>
                            <v-alert v-if="success" dense type="success">
                                {{success}}
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-divider class="primary"/>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn large color="primary" @click="save">
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script>
    import {required, requiredIf} from 'vuelidate/lib/validators'
    import {validationMixin} from 'vuelidate'

    export default {
        name: 'Notification',
        mixins: [validationMixin],
        data: () => ({
            headers: [
                {
                    text: 'Logo',
                    value: 'party_logo',
                    sortable: false,
                    align: 'start'
                },
                {text: 'Name', value: 'party_name'},
                {text: 'Actions', value: 'actions', sortable: false},
            ],
            data: [],
            apiUrl: process.env.VUE_APP_API_URL,
            loading: true,
            dialog: false,
            dialogDelete: false,
            error: '',
            success: '',
            editedIndex: -1,
            editedItem: {
                users: [],
                message: null,
                img_url: null,
                sendNotificationTo: null,
                playerIds: '',
            },
            defaultItem: {
                users: [],
                message: '',
                img_url: null,
                sendNotificationTo: null,
                playerIds: '',
            },
        }),
        methods: {
            checkRequirement(){
                return true;
            },
            onSendNotificationToChange(){
                this.editedItem.playerIds = '';
                this.editedItem.users = [];
            },
            editItem(item) {
                this.editedIndex = this.data.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.editedItem.img_url = null
                this.dialog = true;
            },
            deleteItem(item) {
                this.editedIndex = this.data.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialogDelete = true;
            },
            deleteItemConfirm() {
                this.deletePolitical(this.editedItem.id)
                this.closeDelete();
            },
            close() {
                this.dialog = false;
                this.$v.$reset()
                this.error = ''
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            closeDelete() {
                this.dialogDelete = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            save() {
                this.addNotification()
            },
            //Notification API Calls
            //Notification API Calls
            addNotification() {

                this.error = '';
                this.success = '';
                this.$v.$touch();
                console.log(this.$v.$error);
                if (this.$v.$error) {
                    return;
                }

                let formData = new FormData();
                if(this.editedItem.sendNotificationTo === 'Single Users'){
                    formData.append('playerIds', this.editedItem.playerIds);
                }else{
                    formData.append('users', this.editedItem.users);
                }
                formData.append('message', this.editedItem.message);
                formData.append('img_url', this.editedItem.img_url)
                console.log('adding item : ', formData);
                this.$Axios({
                    method: 'post',
                    url: 'pushnotification',
                    data: formData,
                    headers: {'Content-Type': 'multipart/form-data'},
                })
                    .then((response) => {
                        this.editedItem = this.defaultItem
                        this.$v.$reset();
                        this.success = response.data?.msg ?? 'Notification Sent Successfully!';
                    })
                    .catch((e) => {
                        console.log(e.response?.data);
                        this.error = e.response?.data?.msg ?? 'Something Went wrong please try again later';
                    });
            },
        },
        computed: {
            //// Vuelidate Errors
            messageSendNotificationErrors() {
                const errors = []
                if (!this.$v.editedItem.sendNotificationTo.$dirty) return errors
                !this.$v.editedItem.sendNotificationTo.required && errors.push('Please select to whom notification will be sent!!')
                return errors
            },
            usersErrors() {
                const errors = []
                console.log(this.$v.editedItem.users.requiredIfFunction);
                if (!this.$v.editedItem.users.$dirty) return errors
                !this.$v.editedItem.users.requiredIfFunction && errors.push('Users Type is required !')
                return errors
            },
            messageErrors() {
                const errors = []
                if (!this.$v.editedItem.message.$dirty) return errors
                !this.$v.editedItem.message.required && errors.push('Message is required !')
                return errors
            },
            messagePlayerIdErrors() {
                const errors = []
                if (!this.$v.editedItem.playerIds.$dirty) return errors
                !this.$v.editedItem.playerIds.requiredIfFunction && errors.push('Player Id required !')
                return errors
            },
        },
        watch: {
            dialog(val) {
                val || this.close();
            },
            dialogDelete(val) {
                val || this.closeDelete();
            },
        },
        validations: {
            editedItem: {
                sendNotificationTo: {
                    required
                },
                message: {
                    required
                },
                playerIds: {
                    requiredIfFunction: requiredIf((sm)=>{
                        return sm.sendNotificationTo === 'Single Users';
                    }),
                },
                users: {
                    requiredIfFunction: requiredIf((sm)=>{
                        return sm.sendNotificationTo === 'Group Of Users';
                    }),
                },
            },
        },
    };
</script>